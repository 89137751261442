import React from "react";
import HeroDefault from "../components/Hero/HeroDefault";
import HeroImage from "../components/Hero/HeroImage";
import HeroVideo from "../components/Hero/HeroVideo";
import EmbedVideo from "../components/Hero/EmbedVideo";


export const slugify = str => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    let to   = "aaaaeeeeiiiioooouuuunc------";
    for (let i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export const customColor = color => {

    switch (color) {
        case "LightGrey":
            return "uk-background-muted";
        case "Grey":
            return "uk-background-grey";
        case "DarkGrey":
            return "uk-background-secondary uk-light";
        case "LightWheat":
            return "uk-background-wheat-light uk-text-wheat-darker";
        case "Wheat":
            return "uk-background-wheat uk-text-wheat-darker";
        case "DarkWheat":
            return "uk-background-wheat-dark uk-light";
        case "LightEarth":
            return "uk-background-earth-light uk-text-earth-darker";
        case "Earth":
            return "uk-background-earth uk-light";
        case "DarkEarth":
            return "uk-background-earth-dark uk-light";
        case "LightWine":
            return "uk-background-wine-light uk-light";
        case "Wine":
            return "uk-background-wine uk-light";
        case "DarkWine":
            return "uk-background-wine-dark uk-light";
        case "LightGrape":
            return "uk-background-grape-light uk-text-blue-darker";
        case "Grape":
            return "uk-background-grape uk-light";
        case "DarkGrape":
            return "uk-background-grape-dark uk-light";
        default:
            return "uk-background-default";
    }
}

export const collectionDisplay = format => {
    switch(format) {
        case "carousel":
            return {
                carousel: true,
                count: null
            }
        case "slider":
            return {
                carousel: true,
                count: 1
            }
        default:
            return {
                carousel: false,
                count: null
            }
    }
}

export const loadHero = hero => {
    console.log("HERO REQUEST", hero)
    switch(hero.template) {
        case "Hero Image":
            return <HeroImage data={hero} />
        case "Hero Video":
            return <HeroVideo data={hero} />
        case "Embed Video":
            return <EmbedVideo data={hero} />
        default:
            return <HeroDefault data={hero} />
    }
}

export const textAlign = pos => {
    switch(pos) {
        case "Text Left":
            return "uk-flex-first@s";
        case "Text Right":
            return null;
        default:
            return "uk-flex-first@s";
    }
}
