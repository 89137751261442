import React from "react";
import * as Markdown from 'react-markdown';
import * as style from "./Hero.module.css";

const HeroVideo = ({data, locale}) => {

    const {
        headline,
        subHeadline,
        videoPath,
        slug
    } = data
    const leader = subHeadline ? subHeadline : ""

    return (
        <div id={slug} className={`${style.topWrap} ${style.overlayWrap} uk-position-relative uk-light uk-background-secondary`}>
            <div className={`${style.topWrapHeight} uk-position-relative uk-cover-container uk-light uk-flex uk-flex-middle`}>
                <div className={`${style.topContainer} uk-container uk-container-small uk-flex-auto uk-position-relative uk-margin-medium-top`} data-uk-parallax="y: 0,50; easing:0; opacity:0.2">
                    <div
                        className={`${style.heroCopy} uk-width-1-2@s`}
                        data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150">
                        <h1 className="h1__xlarge">{headline}</h1>
                        <div className="subtitle-text uk-visible@s">
                            <Markdown
                                source={leader}
                                escapeHtml={false}
                            />
                        </div>
                    </div>
                </div>
                <div
                    style={{padding: "56.25% 0 0 0", position: "relative"}}
                    className="uk-box-shadow-large">
                    <iframe
                        src={`${videoPath}?autoplay=1&loop=1&title=0&byline=0&portrait=0`}
                        title="video header"
                        style={{boxShadow: "none", position: "absolute", top: "0", left: "0", width: "100%", height:"100%"}}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        autoPlay
                        allowFullScreen
                    />
                </div>
            </div>
        </div>
    )
}

export default HeroVideo;
